import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Root } from "./Root";

export const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Root} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};
