import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { EventContext, TimeContext, firebase } from "Components";
import { PreShow, Show, PostShow, Register, AdminControls } from "./Components";

const adminViews = ["register", "preshow", "show", "postshow"];
export const Root = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const params = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [registered, setRegistered] = useState(false);
  const [cookies, setCookie] = useCookies([process.env.REACT_APP_EVENT_ID]);
  const [adminView, setAdminView] = useState(null);

  const handleRegister = useCallback(
    (isAdmin) => {
      setCookie("registered", true, { maxAge: 31622400 });
      setRegistered(true);
      if (isAdmin) {
        setCookie("admin", true, { maxAge: 31622400 });
        window.location.reload();
      }
    },
    [setCookie]
  );

  useEffect(() => {
    (async () => {
      if (params.get("token") && !cookies.registered) {
        const verify = firebase.functions().httpsCallable("auth-verify");
        try {
          const verified = await verify({
            eventId: process.env.REACT_APP_EVENT_ID,
            token: params.get("token"),
          });
          if (verified?.data?.success) {
            handleRegister(verified?.data?.admin);
          }
        } catch (error) {
          console.error("token validation failed");
        }
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return null;
  }

  const getAdminView = () => {
    switch (adminView) {
      case "register":
        return <Register onSuccess={handleRegister} event={event} />;
      case "preshow":
        return <PreShow event={event} />;
      case "show":
        return <Show event={event} />;
      case "postshow":
        return <PostShow event={event} />;
      default:
        return null;
    }
  };

  if (cookies?.admin) {
    return (
      <>
        <AdminControls
          views={adminViews}
          selectedView={adminView}
          setAdminView={setAdminView}
        />
        {getAdminView()}
      </>
    );
  }

  if (!cookies?.registered && !registered) {
    return <Register onSuccess={handleRegister} event={event} />;
  }

  if (
    event.start > time.plus({ minutes: event.preshowTransitionMinutes || 30 })
  ) {
    return <PreShow event={event} />;
  }

  if (event.end < time) {
    return <PostShow event={event} />;
  }

  return <Show event={event} />;
};
