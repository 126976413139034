import React from "react";
import { Input, Icon } from "notes";
import { FieldMessage } from "Components";

export const ZipCode = ({ onChange, value, field, error = {}, ...props }) => {
  const handleZip = (value) => {
    if (value.match(/^[0-9\b]+$/) || value === "") {
      onChange({ [field.name]: value });
    }
  };
  return (
    <>
      <Input
        leftIcon={<Icon form name="Marker" />}
        onChange={handleZip}
        value={value}
        placeholder="5-digit code..."
        style={{ width: "184px" }}
        type="tel"
        error={!!error.status}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
