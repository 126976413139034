import React from "react";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "styled-components";
import { TimeProvider, EventProvider } from "Components";
import { Routes } from "./Routes";
import { theme } from "./theme";

function App() {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <TimeProvider>
          <EventProvider>
            <Routes />
          </EventProvider>
        </TimeProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
