import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Layout } from "../Layout";
import styled from "styled-components";
import { GridColumn } from "notes";

export const PostShow = ({ event }) => {
  return (
    <Layout
      videoContent={
        <ThankYou>{ReactHtmlParser(event.postShow?.thankYou)}</ThankYou>
      }
      banner={event.postShow?.banner}
    >
      {event.postShow?.body}
    </Layout>
  );
};

const ThankYou = styled(GridColumn)`
  justify-content: center;
  h2 {
    color: #fff;
    font-size: 120px;
    font-weight: 700;
    line-height: 120px;
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    h2 {
      font-size: 60px;
      line-height: 60px;
    }
    p {
      font-size: 22px;
      line-height: 22px;
    }
  }
`;
