import React, { createContext } from "react";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firebase } from "Components";

export const EventContext = createContext();
export const EventConsumer = EventContext.Consumer;
export const EventProvider = ({ children }) => {
  const [event, loading, error] = useDocumentData(
    firebase.firestore().doc(`events/${process.env.REACT_APP_EVENT_ID}`)
  );

  if (loading) {
    return null; //TODO: loading strategy
  }

  const fullEvent = {
    id: process.env.REACT_APP_EVENT_ID,
    ...event,
    start: DateTime.fromJSDate(event.start.toDate()),
    end: DateTime.fromJSDate(event.end.toDate()),
  };
  return (
    <EventContext.Provider value={{ event: fullEvent, loading, error }}>
      {children}
    </EventContext.Provider>
  );
};
