import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, P } from "notes";

export const Countdown = ({ hours, minutes, seconds }) => {
  return (
    <CountdownContainer xCenter>
      <CountdownTitle>Show Starts In</CountdownTitle>
      <GridRow>
        <Number xCenter>
          <Tick>{hours}</Tick>
          <Label>Hours</Label>
        </Number>
        <Number xCenter>
          <Tick>{minutes}</Tick>
          <Label>Minutes</Label>
        </Number>
        <Number xCenter>
          <Tick>{seconds}</Tick>
          <Label>Seconds</Label>
        </Number>
      </GridRow>
    </CountdownContainer>
  );
};

const CountdownTitle = styled.h3`
  color: #ffffff;
  font-family: "Overpass";
  font-weight: 700;

  margin-top: 0;
  font-size: 70px;
  line-height: 70px;
  margin-bottom: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 41px;
    line-height: 41px;
    margin-bottom: 10px;
    margin-top: -25px;
  }
`;

const Tick = styled(P)`
  color: #fff;
  font-family: "Roboto";
  font-weight: 900;
  font-size: 124px;
  line-height: 140px;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 46px;
    line-height: 50px;
  }
`;

const Label = styled.div`
  color: #ffffff;
  font-family: "Overpass";
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  text-transform: uppercase;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 9px;
    line-height: 12px;
  }
`;

const CountdownContainer = styled(GridColumn)`
  height: 100%;
  padding-top: 80px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: center;
    padding-top: 20px;
  }
`;

const Number = styled(GridColumn)`
  border: 4px solid #ffffff;
  border-radius: 15px;
  height: 288px;
  justify-content: space-between;
  padding: 38px 0;
  width: 236px;
  & + & {
    margin-left: 24px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 15px;
    height: 109px;
    padding: 14px 0;
    width: 89px;
    & + & {
      margin-left: 9px;
    }
  }
`;
