import { MAXTheme } from "notes";

export const theme = {
  ...MAXTheme,
  colors: {
    ...MAXTheme.colors,
    footerFill: "#eae8e8",
    videoBanner: "#d1171e",
    videoFill: "#1e1e1e",
    primary: "#d1171e",
    action: "#d1171e",
    actionLight: "#D9595D",
    buttonPrimaryBgHover: "#9E1116",
    linkDefault: "#7C60D9",
    linkHover: "#9E1116",
    checkboxDisabledCheckedFill: "#9E1116",
  },
  media: {
    ...MAXTheme.media,
    small: "(max-width: 950px)",
    medium: "(min-width: 951px)",
  },
};
