import React from "react";
import { Select as Select2 } from "notes";
import { FieldMessage } from "Components";

export const Select = ({ onChange, value, field, error = {} }) => {
  let options = [
    { text: "Within 7 Days", id: 1 },
    { text: "Within 30 Days", id: 2 },
    { text: "Within 60 Days", id: 3 },
    { text: "After 60 Days", id: 4 },
    { text: "I don't know", id: 5 },
  ];

  let selected = value && options.find(({ text }) => text.includes(value));
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };
  return (
    <>
      <Select2
        placeholder="Select an option..."
        selected={selected}
        options={options}
        onChange={(val) => handleChange(val.text)}
        style={{ cursor: "default" }}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
