import React from "react";
import ReactHtmlParser from "react-html-parser";
import Vimeo from "@u-wave/react-vimeo";
import { Layout } from "../Layout";

export const Show = ({ event }) => {
  return (
    <Layout
      videoContent={
        <Vimeo
          className="vimeo"
          key={event.videoId}
          video={event.videoId}
          autoplay
          responsive
        />
      }
      banner={event.show?.banner}
    >
      {ReactHtmlParser(event.show?.body)}
    </Layout>
  );
};
