import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Checkbox as Checkbox2 } from "notes";
import { FieldMessage } from "Components";

export const OptIn = ({ onChange, value, field, error = {}, ...props }) => {
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };
  return (
    <>
      <Checkbox2
        title={<Formatting>{ReactHtmlParser(field.label)}</Formatting>}
        checked={value}
        error={!!error.status}
        onChange={handleChange}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};

const Formatting = styled.div`
  a {
    color: ${(props) => props.theme.colors.action};
    font-weight: 600;
    text-decoration: none;
  }
`;
