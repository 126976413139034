import TagManager from "react-gtm-module";
import { firebase } from "Components";

const ITEMS_KEY_MAP = {
  item_id: "id",
  item_name: "name",
  item_list_name: "list_name",
  item_list_id: "id",
  item_brand: "brand",
  item_category: "category",
  item_variant: "variant",
  index: "index",
  quantity: "quantity",
  price: "price",
  affiliation: "affiliation",
};

export const fireAnalytics = (event = null, meta = null) => {
  try {
    // gtmPush is used to signal Google Tag Manager to fire a 3rd party conversion event (if required)
    // Fire Standard GA ( Universal Google Analytics )
    window.gtag("event", event, {
      ...meta,
      gtmPush: true,
      send_to: "whitelabelua",
    });

    const { _event, _meta } = formatEventWebApp(event, meta);

    // Fire Firebase Analytics ( Web + App Google Analytics )
    console.log("Firing analytics", _event, _meta);
    firebase.analytics().logEvent(_event, _meta);
  } catch (err) {
    console.log("Error firing analytics", err);
  }
};

//
// Converts the event structure
// From Universal GA
// To Web + App GA
//
const formatEventWebApp = (event, meta) => {
  let _event = event;
  let _meta = {};
  let { items = [], ...rest } = meta;

  let _items = formatItemsWebApp(items);

  switch (event) {
    case "select_content":
      _event = "select_item";
      _meta = {
        ...rest,
        items: _items,
      };
      break;
    case "purchase":
      _meta = {
        ...rest,
        items: _items,
      };
      break;
    default:
      _meta = meta;
      break;
  }

  return { _event, _meta };
};

const formatItemsWebApp = (items) => {
  let _items = [];

  for (let item of items) {
    let _item = {};
    for (const [k, v] of Object.entries(ITEMS_KEY_MAP)) {
      _item[k] = item[v] || null;
    }
    _items.push(_item);
  }

  return _items;
};
