import React from "react";
import styled from "styled-components";
import { GridColumn, GridRow, P } from "notes";
import ToyotaLogo from "Images/toyota-logo-2x.png";

export const Layout = ({ children, videoContent, banner, ...props }) => {
  return (
    <Wrapper>
      <Header>
        <Logo src={ToyotaLogo} />
      </Header>
      {videoContent && (
        <VideoContainer>
          <VideoInner>{videoContent}</VideoInner>
        </VideoContainer>
      )}
      <VideoBanner>
        <Container xCenter yCenter>
          <Title>{banner}</Title>
        </Container>
      </VideoBanner>
      <Body {...props}>{children}</Body>
      <Footer>
        <FooterLogo src={ToyotaLogo} />
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn)`
  background: #fff;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.65);
  margin: 0 auto;
  position: relative;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    align-items: center;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: 1140px;
    width: 100%;
    min-height: 961px;
  }
`;

const Container = styled(GridRow)`
  height: 100%;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: center;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const Header = styled(Container)`
  @media only screen and ${(props) => props.theme.media.medium} {
    justify-content: left;
  }
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
  margin: 24px 0;
  max-width: 181px;
`;

const VideoContainer = styled.div`
  background-color: ${(props) => props.theme.colors.videoFill};
  display: flex;
  padding-top: 56.24%;
  position: relative;
  width: 100%;
`;

const VideoInner = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0%;
  right: 0px;
  bottom: 0;
  left: 0px;
  .vimeo {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const VideoBanner = styled(GridRow)`
  background-color: ${(props) => props.theme.colors.videoBanner};
  box-sizing: border-box;
  height: 89px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    height: 54px;
  }
`;

const Title = styled.h3`
  color: #fff;
  font-family: "Overpass";
  font-size: 45px;
  line-height: 45px;
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
  z-index: 5;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 32px;
    line-height: 32px;
  }
`;

const Body = styled(GridColumn)`
  background-color: #ffffff;
  padding: 40px;
  justify-content: center;
  text-align: center;
  p + p,
  p + h3 {
    margin-top: 24px;
  }
  h3 {
    color: #222222;
    font-family: "Overpass";
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 40px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    min-height: 200px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    h3 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
`;

const Footer = styled(GridRow)`
  background-color: ${(props) => props.theme.colors.footerFill};
  padding: 60px 32px;
  justify-content: center;
  width: 100%;
`;

const FooterLogo = styled.img`
  height: 100%;
  width: 100%;
  max-width: 302px;
`;
