export const registrationFields = [
  {
    id: "T6evsspkFEmLryuL8Zoq3A",
    order: 1,
    label: "What's your name?",
    type: "double",
    required: true,
    placeholder: ["First or given name...", "Last or family name..."],
    name: "name",
    names: ["firstName", "lastName"],
  },
  {
    id: "Lsxemw4Iu0GmHBTbKXsaTQ",
    order: 2,
    label: "What is your residential ZIP / Postal Code?",
    type: "zip",
    required: true,
    name: "zipCode",
  },
  {
    id: "t3h6tqGPUkupDFjFTSn1qQ",
    order: 3,
    label: "What's your email address?",
    type: "email",
    required: true,
    placeholder: "john@email.com",
    name: "email",
  },
  {
    id: "LybtjoFgYEWPqDVh92lF4A",
    order: 4,
    label: "Phone Number (optional)",
    type: "phone",
    required: false,
    name: "phone",
  },
  {
    id: "uwebfhoweijfn",
    order: 5,
    required: true,
    label: "When are you looking to purchase or lease a new vehicle?",
    type: "select",
    name: "intent",
    placeholder: "Select an option...",
  },
  {
    id: "ijhgnwiemfjiwnbgyi",
    order: 6,
    required: false,
    label:
      "Please contact me by email regarding purchasing or leasing a new Toyota vehicle.",
    type: "optIn",
    name: "emailOptIn",
  },
  {
    id: "weyfgbhwijefnwoef",
    order: 7,
    required: false,
    label:
      "Please contact me by phone regarding purchasing or leasing a new Toyota vehicle.",
    type: "optIn",
    name: "phoneOptIn",
  },
  {
    id: "eroguenroigm",
    order: 8,
    required: true,
    label:
      "I agree to the terms and conditions and the <a target='_blank' href='https://rileygreen.settheset.com/privacy'>Set the Set</a>, <a target='_blank' href='https://www.toyota.com/support/privacy-rights'>Toyota</a> and <a target='_blank' href='https://www.bigmachinelabelgroup.com/privacy-policy'>Big Machine Label Group</a> privacy policies.",
    type: "optIn",
    name: "tos",
  },
];
