import React, { useState, useReducer } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { GridColumn, Label, ButtonPrimary, withDefaultMedia, H3 } from "notes";
import { registrationFields } from "Data";
import { Field, handleErrors, firebase } from "Components";
import { reducer } from "Modules";
import { Layout } from "../Layout";
import MobilePhoto from "Images/Hero_MOBILE_v2.jpg";
import SidePhoto from "Images/Hero_RIGHT-COL_v2.jpg";
import { fireAnalytics } from "services";

const initRegistration = {
  name: {
    firstName: "",
    lastName: "",
  },
  zipCode: "",
  email: "",
  phone: "",
  intent: "",
  emailOptIn: false,
  phoneOptIn: false,
  tos: false,
};

export const Register = withDefaultMedia(
  React.memo(({ matches, event, onSuccess }) => {
    const [registration, setRegistration] = useReducer(
      reducer,
      initRegistration
    );
    const [errors, setErrors] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleChange = (value, name) => {
      setRegistration(value);
      delete errors[name];
      setErrors(errors);
    };

    const handleSubmit = async () => {
      let errorObject = handleErrors(registrationFields, registration);

      if (!errorObject) {
        setSubmitting(true);
        try {
          const submitForm = firebase.functions().httpsCallable("leads-submit");
          const result = await submitForm({
            form: {
              FNAME: registration.name.firstName,
              LNAME: registration.name.lastName,
              ZIP: registration.zipCode,
              PHONE: registration.phone,
              OPTIN: true,
              OPTINEMAIL: registration.emailOptIn,
              OPTINPHONE: registration.phoneOptIn,
              INTENT: registration.intent,
            },
            email: registration.email,
            form_id: process.env.REACT_APP_FORM_ID,
          });
          onSuccess();
          setSubmitting(false);
          fireAnalytics("form_submit", {
            event_category: event.artist,
            event_label: event.title,
            formId: process.env.REACT_APP_FORM_ID,
            setLiveEventId: process.env.REACT_APP_EVENT_ID,
          });
        } catch (error) {
          setSubmitting(false);
        }
      } else {
        setErrors(errorObject);
      }
    };

    return (
      <Layout banner={event.register?.heading} style={{ padding: 0 }}>
        <ColumnContainer>
          <Left>
            <Container style={{ textAlign: "left" }}>
              <BodyContainer>
                {ReactHtmlParser(event.register?.body)}
                {matches.small && ReactHtmlParser(event.register?.bio)}
              </BodyContainer>
              <H3 style={{ margin: "24px 0" }}>
                {event.register?.registerHeading}
              </H3>
              {registrationFields
                .sort((a, b) => a.order - b.order)
                .map((field) => {
                  return (
                    <QuestionContainer
                      fixSize={["text", "email", "double", "phone"].includes(
                        field.type
                      )}
                      key={field.id}
                    >
                      {field.type !== "optIn" && <Label>{field.label}</Label>}
                      <Field
                        field={field}
                        value={registration[field.name]}
                        onChange={(value) => handleChange(value, field.name)}
                        error={{
                          status: errors[field.name],
                          message:
                            "This is a required field and cannot be blank.",
                        }}
                      />
                    </QuestionContainer>
                  );
                })}
              <ButtonContainer columns={2}>
                {submitting ? (
                  <ButtonPrimary disabled>Loading</ButtonPrimary>
                ) : (
                  <ButtonPrimary onClick={() => handleSubmit()}>
                    Submit
                  </ButtonPrimary>
                )}
              </ButtonContainer>
            </Container>
          </Left>
          <Right>
            <SwitchedImage />
            {!matches.small && (
              <BioContainer>
                {ReactHtmlParser(event.register?.bio)}
              </BioContainer>
            )}
          </Right>
        </ColumnContainer>
      </Layout>
    );
  })
);

const SwitchedImage = withDefaultMedia(({ matches }) => (
  <ResponsiveImage src={matches.small ? MobilePhoto : SidePhoto} />
));

const ResponsiveImage = styled.img`
  display: block;
  width: 100%;
`;

const Left = styled.div`
  padding: 0 12px;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding: 0;
  }
`;
const Right = styled.div`
  background: ${(props) => props.theme.colors.primary};
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 570px;
    flex-shrink: 0;
  }
`;

const BioContainer = styled.div`
  text-align: left;
  padding: 12px;
  color: #fff;
`;

const BodyContainer = styled.div``;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-direction: row;
  }
`;

const Container = styled(GridColumn)`
  margin: 24px 0 96px 0;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const QuestionContainer = styled(GridColumn)`
  ${(props) => props.fixSize && "max-width: 392px"};
  & + & {
    margin-top: 24px;
  }
  & > ${Label} {
    margin-bottom: 4px;
  }
`;

const ButtonContainer = styled(GridColumn)`
  margin-top: 52px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 24px;
  }
`;
